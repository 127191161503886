class restApi{
    //Set domain name here
    //static domain = "http://localhost:5001";
    static domain = "https://api.geminigroupng.com"
    static domainState = false;

    static PostApi(url, requestObject, shouldAddAuthorization=false, isDomainUsed=restApi.domainState){      
        if(!isDomainUsed){
            url = restApi.domain + url;
        }
       // console.log(requestObject)
        return new Promise((resolve,reject)=>{
            fetch(url, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                headers: shouldAddAuthorization ? {
                    'Authorization': 'Bearer ' + localStorage.getItem("gemini_tkn123908"),
                    'Content-Type': 'application/json'
                } : {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestObject)
            })
            .then(Response=>Response.json())
            .then(result=>{
                /*if(!result.isSuccessful && result.status==403){
                    localStorage.removeItem('cybfrauddet_tkn');
                    alert("Authentication failed. Please re-login.")
                    window.location.href = "/account/login";
                }
                else resolve(result)*/
                resolve(result);
            })
            .catch(error=>{
                console.log(error,'this error')
                resolve({
                    succeed: false,
                    message:"Connectivity error. Please check internet network and try again"
                });
            })
        }) 
    }
    static getApi(url,authorizationString=false,isDomainUsed = restApi.domainState){
        if(!isDomainUsed){
            url = restApi.domain + url;
        }

        return new Promise((resolve,reject)=>{
            fetch(url,{
                method: 'GET',
                mode: 'cors',
                cache: 'no-cache',
                headers: authorizationString && {
                    'Authorization': 'Bearer ' + localStorage.getItem("gemini_tkn123908"),
                }
            })
            .then(Response=>Response.json())
            .then(result=>{
                /*if(!result.isSuccessful && result.status==403){
                    localStorage.removeItem('eduplus_tkn');
                    alert("Authentication failed. Please re-login.")
                    window.location.href = "/account/login";
                }
                else resolve(result)*/
                resolve(result);
            })
            .catch(error=>{
                console.log(error,'this error')
                resolve({
                    succeed: false,
                    message:"Connectivity error. Please check internet network and try again"
                });
            })
        })
    }
}
export default restApi;