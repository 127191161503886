import { createSlice } from "@reduxjs/toolkit";

export const adminSlice = createSlice({
    name: 'admin',
    initialState:{
        jobs: [],
        allApplications:[],
        applications: [],
        user: {}
    },
    reducers:{
        setJobs: (state,action)=>{
            state.jobs = action.payload    
        },
        AddToJobs: (state,action)=>{
            state.jobs = [
                ...state.jobs,
                action.payload
            ]
        },
        deleteJob: (state, action)=>{
            state.jobs = state.jobs.filter(x => x.id != action.payload.id)
        },
        editJob: (state,action)=>{
            state.jobs = state.jobs.map(job=>job.id==action.payload.id ? action.payload : job);
        },
        setJobAvailability: (state, action)=>{
            state.jobs = state.jobs.map(job=>job.id==action.payload.id ? {
                ...job, is_available : action.payload.is_available
            } : job);
        },
        setApplications: (state,action)=>{
            state.allApplications = action.payload;
            state.applications = action.payload;
        },
        filterApplications: (state, action)=>{
            state.applications = state.allApplications.filter(x => x.job_id == Number(action.payload.id))
        },
        deleteApplications: (state, action)=>{
            state.allApplications = state.allApplications.filter(x => x.id != Number(action.payload.id));
            state.applications = state.applications.filter(x => x.id != Number(action.payload.id))
        }
    }
})

export const {setJobs, setApplications, filterApplications,
deleteJob, editJob, setJobAvailability, AddToJobs, deleteApplications} = adminSlice.actions;
export default adminSlice.reducer;