import style from "./css/home.module.css";
import { Routes, Route, Link, useLocation } from "react-router-dom";
import Api from "../../helperFunctions/Api";
import {BsPersonCircle} from "react-icons/bs"
import {BsCaretDownFill} from "react-icons/bs";
import Dashboard from "../Dashboard"
import AddJob from "../AddJob";
import { useState, useEffect, createContext } from "react";
import { setJobs,setApplications } from "../../redux/reducers/adminReducer";
import {useDispatch, useSelector} from "react-redux";
import logo from "../../images/logo.jpg";
import ViewApplications from "../ViewApplications";
import ViewJobs from "../ViewJobs";

export const JobEditContext = createContext();

const Home = () =>{
    const dispatch = useDispatch();
    const [menuCnt, setMenuCnt] = useState(0);
    const [editJob, setEditJob] = useState({});

    const fetchDatas = ()=>{
        Api.getApi('/get-all-jobs',true)
        .then(result=>{
            console.log(result)
            if(result.isSuccessful)
                dispatch(setJobs(result.data))
            
        })
        Api.getApi('/get-applications',true)
        .then(result=>{
            console.log(result)
            if(result.isSuccessful)
                dispatch(setApplications(result.data))
            
        })
    }

    useEffect(()=>{
        fetchDatas();
    });

    return(
        <>
           <div className={style.home}>
                <div className={style.sidebar}>
                    <div className={style.sidebarheader}>
                      <img src={logo} width="100"/>
                      <span className={style.brand}>ADMIN PANEL</span>
                    </div>
                    <div className={style.menu}>
                        <Link to="/dashboard/add-job" onClick={()=>setMenuCnt(1)} className={`${menuCnt == 1 && style.linkstyle}`}>ADD JOB</Link>
                        <Link to="/dashboard/view-jobs" onClick={()=>setMenuCnt(2)} className={`${menuCnt == 2 && style.linkstyle}`}>VIEW JOBS</Link>             
                        <Link to="/dashboard/view-applications" onClick={()=>setMenuCnt(3)} className={`${menuCnt == 3 && style.linkstyle}`}>VIEW APPLICATIONS</Link>
                    </div>
                </div>
                <div className={style.content}>
                    <div className={style.navbar}>
                       <div className={style.rightnav}>
                         <span className={style.iconPersonCircle}><BsPersonCircle  /></span>
                         <span>Hi Admin</span>
                         <span><BsCaretDownFill /></span>
                       </div>
                    </div>
                    <div className={style.mainContent}>
                        <JobEditContext.Provider value={{editJob, setEditJob}}>
                            <Routes>
                            <Route index element={<AddJob />}/>
                            <Route path="/add-job" element={<AddJob />}/>
                            <Route path="/view-jobs" element={<ViewJobs />}/>
                            <Route path="/view-applications" element={<ViewApplications />}/>
                            </Routes>
                        </JobEditContext.Provider>
                    </div>
                </div>
           </div>
        </>
    );
}

export default Home;