import { useEffect,useState,useRef } from "react";
import Api from "../../helperFunctions/Api";
import {useDispatch, useSelector} from "react-redux";
import { setApplications, filterApplications, deleteApplications } from "../../redux/reducers/adminReducer";

const ViewApplications = ()=>{
    const dispatch = useDispatch();
    const jobs = useSelector((state)=>state.admin.jobs);
    const applicants = useSelector((state)=>state.admin.applications);

    return(
        <>
            <div>
                <span>Filter By: </span><select className="appSelect" onChange={(e)=>dispatch(filterApplications({id: e.target.value}))}>
                    <option value="0">Select Job</option>
                    {
                        jobs.map((job, index)=>(
                            <option key={job.id} value={job.id}>{job.title}</option>
                        ))
                    }
                </select>
                <div>
                    {
                        (applicants.map((applicant,index)=>(
                            <div className="appDiv" key={applicant.id}>
                                <h3>APPLICANT {index + 1}</h3>
                                <a target="_blank" href={`https://api.geminigroupng.com/upload/cvs/${applicant.resume}`}>View CV</a><br/>
                                <span><span>First Name : </span><span>{applicant.first_name}</span></span><br/>
                                <span><span>Last Name : </span><span>{applicant.last_name}</span></span><br/>
                                <span><span>Job Title : </span><span>{applicant.job_title}</span></span><br/>
                                <span><span>Email : </span><span>{applicant.email}</span></span><br/>
                                <span><span>Salary : </span><span>{applicant.salary}</span></span><br/><br/>
                                <a onClick={()=>{
                                    if(window.confirm("Are you sure you want to delete this application?")){
                                        Api.getApi('/delete-application/' + applicant.id,true)
                                        .then(result=>{
                                            alert(result.message);
                                            dispatch(deleteApplications({id: applicant.id}));
                                        })
                                    }
                                }}>Delete Application</a>
                            </div>
                        )))
                    }
                </div>
            </div>
        </>
    )
}

export default ViewApplications;