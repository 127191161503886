import addPcss from "../../css/inputs.module.css";
import { useRef, useEffect, useState, useContext } from "react";
import Api from "../../helperFunctions/Api";
import JoditEditor from 'jodit-react';
import Loading from '../../components/loading';
import { useNavigate } from "react-router-dom";
import {useDispatch} from "react-redux";
import { editJob as adminEditJob, AddToJobs} from "../../redux/reducers/adminReducer";
import {JobEditContext} from "../home"

const AddJob = ()=>{

    const editorRef = useRef(null);
    const title = useRef(); const description = useRef();
    const department = useRef();
    const location = useRef();
    const employmentType = useRef();
    const isAvailable = useRef();
    const [editorContent,setEditorContent] = useState('');
    const [btnText, setBtnText] = useState('Add Job')

    const [showLoading, setShowLoading] = useState(false);
    const {editJob, setEditJob} = useContext(JobEditContext);
    //const jobDetails = useRef();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const config = {
        readonly:false,
        height:400,
    }

    const handleEditor = (event) =>{
        if(event){
            //console.log(event);
            let content = event.innerHTML;
            setEditorContent(content);
        }     
    }

    const handleSubmit = (event)=>{
        event.preventDefault();

        setShowLoading(true);

        if(editJob.id){
            const jobs = {
                id: editJob.id,
                title: title.current.value.trim(),
                description: description.current.value,
                location: location.current.value,
                department: department.current.value,
                employment_type: employmentType.current.value,
                details: editorRef.current.value
            }
            console.log(jobs,"user")
            Api.PostApi('/edit-jobs', jobs, true)
            .then(response=>{  
                console.log(response, "response");
                setShowLoading(false)
                alert(response.message);
                setEditJob({});
                navigate('/dashboard/view-jobs')
                if(response.isSuccessful){
                    dispatch(adminEditJob(jobs))
                }
                else{
                    
                    console.log(response)
                }
                //console.log(response) 
                
            })
            .catch(err=>{
                //console.log(err)
                setShowLoading(false)
                alert("Action failed. Wrong username or password")
            })
        }
        else{
            const job = {
                title: title.current.value.trim(),
                description: description.current.value,
                location: location.current.value,
                department: department.current.value,
                employment_type: employmentType.current.value,
                details: editorRef.current.value
            }
            console.log(job,"user")
            Api.PostApi('/add-job', job, true)
            .then(response=>{  
                console.log(response, "response");
                setShowLoading(false)
                alert(response.message);
                if(response.isSuccessful){
                    job.id = response.data.id;
                    dispatch(AddToJobs(job));
                }
                else{
                    
                    console.log(response)
                }
                //console.log(response) 
                
            })
            .catch(err=>{
                //console.log(err)
                setShowLoading(false)
                alert("Action failed. Wrong username or password")
            })
        }
    }

    useEffect(()=>{
        if(editJob.id){
            setBtnText("Edit Job")
            editorRef.current.value = editJob.details
            title.current.value = editJob.title;
            description.current.value = editJob.description;
            department.current.value = editJob.department;
            location.current.value = editJob.location;
            employmentType.current.value = editJob.employment_type;
            isAvailable.current.value = editJob.is_available;
        }
    })

    return(
        <>
           <Loading shouldShow={showLoading} />
           <form onSubmit={handleSubmit}>
            <h1 style={{color: 'var(--main-color)',fontSize:30}}>ADD JOB</h1>
                <span>Job Title<span className={addPcss.required}>*</span></span><br />
                <input type="text" ref={title} className={addPcss.input} placeholder="Enter job title" /><br />
                <span>Description<span className={addPcss.required}>*</span></span><br />
                <textarea type="text" ref={description} className={addPcss.input} placeholder="Enter job description"/><br />

                <span>Department<span className={addPcss.required}>*</span></span><br />
                <input type="text" ref={department} className={addPcss.input} placeholder="Enter department" /><br />

                <span>Location<span className={addPcss.required}>*</span></span><br />
                <input type="text" ref={location} className={addPcss.input} placeholder="Enter location" /><br />

                <span>Employment Type<span className={addPcss.required}>*</span></span><br />
                <select type="text" ref={employmentType} className={addPcss.input} placeholder="Enter email">
                    <option value="Full Time">Full Time</option>
                    <option value="Hybrid">Hybrid</option>
                    <option value="Remote">Remote</option>
                </select><br />

                <span>Is Job Available<span className={addPcss.required}>*</span></span><br/>
                <select ref={isAvailable} className={addPcss.input}>
                    <option value="false">False</option>
                    <option value="true">True</option>
                </select><br/>

                <span>Job Details<span className={addPcss.required}>*</span></span><br/>
                <JoditEditor 
                        ref={editorRef}
                        config={config}
                        value={editorContent}
                        onBlur={handleEditor}
                        onChange={(e)=>handleEditor(e)}
                    />

                <p className={addPcss.btnP}>
                <button className="cancelBtn" onClick={()=>{
                    setEditJob({}); navigate('/dashboard/view-jobs');
                }} style={{display: editJob.id ? `inline-block`: `none`}}>Cancel</button>
                    <input type="submit" className={addPcss.btn} value={btnText}/>
                </p>
           </form>
        </>
    )
}

export default AddJob;