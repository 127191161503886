import { useState, useContext } from "react";
import Api from "../../helperFunctions/Api";
import {useDispatch, useSelector} from "react-redux";
import Modal from "../../components/modal";
import Modal2 from "../../components/modal2";
import Loading from "../../components/loading";
import style from "../../css/table.module.css";
import { useNavigate } from "react-router-dom";
import { deleteJob, setJobAvailability} from "../../redux/reducers/adminReducer";
import {JobEditContext} from "../home"

const ViewJobs = ()=>{
    const jobs = useSelector((state)=>state.admin.jobs);
    const [showLoading, setShowLoading] = useState(false);
    const [modalToggle, setModalToggle] = useState(false);
    const [modalText, setModalText] = useState("");
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const [modalToggle2, setModalToggle2] = useState(false);
    const [modalText2, setModalText2] = useState("");

    const {editJob, setEditJob} = useContext(JobEditContext);

    //<td>{job.created_at.split('T')[0]} {job.created_at.split('T')[1].slice(0,5)}</td>
    return(
        <>
           <Modal modalObject={{ header: "", footer: "", body: modalText }} modalTogglee={modalToggle} closeModal={() => setModalToggle(false)} />
           <Modal2 modalObject={{ header: "", footer: "", body: modalText2 }} modalTogglee={modalToggle2} closeModal={() => setModalToggle2(false)} />
           <Loading shouldShow={showLoading} />
           <table className={style.table}>
                <thead>
                    <tr>
                        <th>S/N</th>
                        <th>Title</th>
                        <th>Description</th>
                        <th>Employment Type</th>
                        <th>Location</th>
                        <th>Action</th>
                    </tr>
                </thead>
                    {
                        (jobs.map((job,index)=>(
                            <tr >
                                <td>{index + 1}</td>
                                <td>{job.title}</td>
                                <td>{job.description}</td>
                                <td>{job.employment_type}</td>
                                <td>{job.location}</td>
                                
                                <td className={style.actionLink}>
                                    <span onClick={()=>{
                                        setModalText2(job.details);
                                        setModalToggle2(true);
                                    }}>View Job Description</span><br/>
                                    <span onClick={()=>{
                                        if(window.confirm("Are you sure you want to delete this job? Deleting this job will delete all applications under this job")){
                                            Api.getApi('/delete-jobs/' + job.id,true)
                                            .then(result=>{
                                                setModalText(result.message)
                                                setModalToggle(true);
                                                dispatch(deleteJob({id: job.id}))
                                            })
                                        }
                                    }}>Delete Job</span><br/>
                                    <span onClick={()=>{
                                        if(window.confirm("Are you sure you want to change the availability state of this job")){
                                            Api.PostApi('/change-job-availability',{
                                                id: job.id,
                                                is_available: job.is_available ? 0 : 1
                                            }, true)
                                            .then(result=>{
                                                setModalText(result.message)
                                                setModalToggle(true);
                                                dispatch(setJobAvailability({
                                                    id: job.id,
                                                    is_available: job.is_available ? 0 : 1
                                                }))
                                            })
                                        }
                                    }}>{job.is_available? 'Set To Unavailable' : 'Set To Available'}</span><br/>
                                    <span onClick={()=>{
                                        setEditJob(job);
                                        navigate('/dashboard/add-job');
                                    }}>Edit Job</span>
                                </td>
                            </tr>
                        )))
                    }
            </table>
        </>
    )
}

export default ViewJobs;