import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './views/home'
import Login from './views/Login'
import './css/App.css'

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/dashboard/*" element={<Home />} />
          <Route path="/" element={<Login />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;