import addPcss from "../../css/inputs.module.css";
import { useRef, useState } from "react";
import Api from "../../helperFunctions/Api";
import logincss from "../../css/login.module.css";
import Logo from "../../images/logo2.jpg";
import { useNavigate } from "react-router";
import Loading from "../../components/loading";
//const pass = 'Pass12345+'
const Login = () =>{
    const username = useRef();
    const password = useRef();
    const [shLoading, setShLoading] = useState(false);

    let navigate = useNavigate();

    const handleSubmit = (event)=>{
        event.preventDefault();

        setShLoading(true);
            const user = {
                userRole:'root',
                login: username.current.value.trim(),
                password: password.current.value.trim()
            }
            //console.log(user,"user")
            Api.PostApi('/login/submit', user)
            .then(response=>{  
                console.log(response, "response");
                console.log(user.password, "entered password")
                setShLoading(false)
                if(response.isSuccessful){
                    localStorage.setItem("gemini_tkn123908", response.token);
                    localStorage.setItem("name", response.name);
                
                    navigate('/dashboard/')
                }
                else{
                    alert(response.message);
                    console.log(response)
                }
                //console.log(response) 
                
            })
            .catch(err=>{
                console.log(err, "error")
                setShLoading(false)
                alert("Action failed. Wrong username or password1")
            })
    }

    return(
    <form onSubmit={handleSubmit}>
        <div className={`col-12 ${logincss.loginDiv}`}>
            <div className={`col-6`}>
                <div>
                    <img src={Logo} width="250" /><br />
                </div>
            </div>
            <div className={`col-6 col-xs-12`}>
                <div>
                <h1 style={{color: 'var(--main-color)',fontSize:30}}>Login</h1>
                <span>Email<span className={addPcss.required}>*</span></span><br />
                <input type="text" ref={username} className={addPcss.input} placeholder="Enter email" /><br />
                <span>Password<span className={addPcss.required}>*</span></span><br />
                <input type="password" ref={password} className={addPcss.input} placeholder="Enter password" /><br />
                <p className={addPcss.btnP}>
                    <input type="submit" className={addPcss.btn} value="Login"/>
                </p>
              
                </div>
                
            </div>
        </div>
        <Loading shouldShow={shLoading} />  
    </form>)
}
export default Login